import {getPathPrefix, getRecord, getRecords, postRecord, putRecord, removeRecord} from "@/services/api";

export function getDispatchers(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/dashboard/' + getPathPrefix() + 'dispatchers.json', filterQuery, options)
}

export function getDispatcher(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/dashboard/' + getPathPrefix() + 'dispatchers/' + id + '.json', filterQuery, options)
}

export function postDispatcher(data, options = {}) {
    return postRecord('/prologistics/dashboard/' + getPathPrefix() + 'dispatchers.json', data, options)
}

export function putDispatcher(id, data, options = {}) {
    return putRecord('/prologistics/dashboard/' + getPathPrefix() + 'dispatchers/' + id + '.json', data, options)
}

export function removeDispatcher(id, options = {}) {
    return removeRecord('/prologistics/dashboard/' + getPathPrefix() + 'dispatchers/', id, options)
}